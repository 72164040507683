define('blackkaweah/router', ['exports', 'ember', 'blackkaweah/config/environment'], function (exports, _ember, _blackkaweahConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _blackkaweahConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('about');
    this.route('music');
    this.route('video');
    this.route('contact');
    this.route('donate');
  });

  exports['default'] = Router;
});